import(/* webpackMode: "eager", webpackExports: ["Root","displayName","List","Item","Trigger","Content","Link","Viewport","Indicator"] */ "/home/runner/work/c_social/c_social/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/runner/work/c_social/c_social/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Quicksand-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/Quicksand-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/Quicksand-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/Quicksand-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./fonts/Quicksand-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-quicksand\"}],\"variableName\":\"quickSandFont\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/alert-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/drawer.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/form.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/label.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/popover.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/select.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/sheet.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/toast.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/provider/clerk-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/provider/nprogress-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/provider/query-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/provider/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/styles/index.css");
